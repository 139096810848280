<template>
  <div class="page">
    
  </div>
</template>

<style scoped lang="scss">

</style>
<script>
  import CompanyInfo from '@/model/CompanyInfo';
  export default {
    components: {  },
    data: () => ({
      status: {}
    }),
    created(){
      CompanyInfo.getCompanyStatus(this.$store.state.user.companyCode).then((result) => {
        this.status = result.data;
        CompanyInfo.changeStatus( this.$store.state.user.companyCode, {
          statusName: 'IS_REGISTRATION_COMPLETED',
          status: true
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      }).catch(reason => this.$errorMessage(undefined, {reason}));
    },
    methods:{
      toDashboard(){
        this.$router.push('/supplier/manage');
      }
    }
  }
</script>